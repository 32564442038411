import VueRouter from "vue-router";

const myprofileComponent = () =>
  import("../components/User/profile/MyprofileComponent.vue");

const editprofileComponent = () =>
  import("../components/User/profile/EditProfileComponent.vue");

const CreateNewPasswordComponent = () =>
  import("../components/Auth/CreateNewPasswordComponent.vue");

const GameHIstoryComponent = () =>
  import("../components/History/GameHistoryComponent.vue");

const ForgetPasswordComponent = () =>
  import("../components/Auth/ForgetPasswordComponent.vue");

const landingComponent = () =>
  import("../components/landing/landingComponent.vue");
const SignInComponent = () => import("../components/Auth/SignInComponent.vue");
const SignupComponent = () => import("../components/Auth/SignupComponent.vue");
const DropdownComponent = () =>
  import("../components/GenerationAgeDropdown/DropdownComponent.vue");
const MainComponent = () => import("../components/main/MainComponent.vue");
const HomeComponent = () => import("../components/Home/HomeComponent.vue");
const InstructionComponent = () =>
  import("../components/common/InstructionComponent.vue");
const SupportComponent = () =>
  import("../components/common/SupportComponent.vue");
const gameComponent = () => import("../components/Quiz/gameComponent.vue");
const scoreComponent = () => import("../components/Score/ScoreComponent.vue");
const previousAnswerComponent = () =>
  import("../components/Previous/PreviousAnswerComponent.vue");
const AboutusComponent = () =>
  import("../components/common/AboutusComponent.vue");
const shareUsComponent = () =>
  import("../components/share/scoreShareComponent.vue");

const previousShareUsComponent = () =>
  import("../components/share/previousShareComponent.vue");

const routeGuard = () => {
  let user =  JSON.parse(localStorage.getItem("userDetails"));
  if(user && user.token != ""){
    return true;
  } else {
    return false;
  }
};

const router = new VueRouter({
  mode: "history",
  hash: false,
  routes: [
    /* landing for both users(guest & register) routes */
    {
      path: "/",
      name: "landing",
      component: landingComponent,
      beforeEnter: (to, from, next) => {
        if (routeGuard()) {
          router.push({ name: "home" });
        } else {
          next();
        }
      },
    },
    {
      path: "/dropdown",
      name: "dropdown",
      component: DropdownComponent,
      beforeEnter: (to, from, next) => {
        if (localStorage.getItem("user") === "true") {
          router.push({ name: "home" });
        } else {
          next();
        }
      },
    },
    /*Auth  routes start */
    {
      path: "/signin",
      name: "signin",
      component: SignInComponent,
      beforeEnter: (to, from, next) => {
        if (routeGuard()) {
          router.push({ name: "home" });
        } else {
          next();
        }
      },
    },
    {
      path: "/signup",
      name: "signup",
      component: SignupComponent,
      beforeEnter: (to, from, next) => {
        if (routeGuard()) {
          router.push({ name: "home" });
        } else {
          next();
        }
      },
    },

    {
      path: "/forget-password",
      name: "forgetpassword",
      component: ForgetPasswordComponent,
    },

    {
      path: "/create-password",
      name: "createpassword",
      component: CreateNewPasswordComponent,
    },
    {
      path: "/share/:id",
      name: "share",
      component: shareUsComponent,
    },
    

    {
      path: "/answer/share",
      name: "shareanswer",
      component: previousShareUsComponent,
    },

    /* Main pages routes both users(guest & register) start*/
    {
      path: "/home",
      component: MainComponent,
      // beforeEnter: (to, from, next) => {
      //   if (localStorage.getItem("user") != "true") {
      //     router.push({ name: "dropdown" });
      //   } else {
      //     next();
      //   }
      // },

      children: [
        { path: "", name: "home", component: HomeComponent },
        {
          path: "instruction",
          name: "instruction",
          component: InstructionComponent,
        },
        {
          path: "about-us",
          name: "about-us",
          component: AboutusComponent,
        },
        { path: "support", name: "support", component: SupportComponent },
        {
          path: "quiz",
          name: "quiz",
          component: gameComponent,
          beforeEnter: (to, from, next) => {
            if (from.fullPath === "/" || from.name === "score") {
              router.push({ name: "home" });
            } else {
              next();
            }
          },
        },
        {
          path: "score",
          name: "score",
          component: scoreComponent,
        },
        {
          path: "previous",
          name: "previous",
          component: previousAnswerComponent,
          beforeEnter: (to, from, next) => {
            if (localStorage.getItem("finalScore")) {
              next();
            } else {
              router.push({ name: "home" });
            }
          },
        },
        {
          path: "my-profile",
          name: "myprofile",
          component: myprofileComponent,
        },
        {
          path: "edit-profile",
          name: "editprofile",
          component: editprofileComponent,
        },

        {
          path: "game-history",
          name: "gamehistory",
          component: GameHIstoryComponent,
        },
      ],
    },
  ],
});

export default router;
