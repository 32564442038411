import axios from "axios";

export default {
  namespaced: true,
  state: {
    listings: [],
    generationState: {},
  },

  mutations: {
    generationID(state){
      let value   = JSON.parse(localStorage.getItem('userDetails'));
      state.generationState = {id:parseInt(value.userDetails.generation)}
    },
    resetState(state) {
      state.listings = [];
      state.generationState = {};
    },
    dataList(state, data) {
      state.listings = data;
    },
    generationData(state, data) {
      state.generationState = data;
    },
  },

  actions: {

   
    async generationListData({ commit }) {
      try {
        await axios
          .get(`${process.env.VUE_APP_API_URL}/generation-list`)
          .then((res) => {
            if (res.data.success === true && res.data.status === 200) {
              commit("dataList", res.data.data);
            }
          })
          .catch((res) => {
            console.log(res);
          });
      } catch (error) {
        console.error("Error fetching generation data:", error);
      }
    },

    async selectDate({ commit }, payload) {
      try {
        await axios
          .post(
            `${process.env.VUE_APP_API_URL}/generation-name`,
            payload
          )
          .then((res) => {
            if (res.data.success === true && res.data.status === 200) {
              commit("generationData", res.data.data[0]);
            }
          })
          .catch((res) => {
            console.log(res);
          });
      } catch (error) {
        console.error("Error fetching generation data:", error);
      }
    },

    async formDataSubmit(
      /* eslint-disable */
      { commit },
      payload
    ) {
      localStorage.setItem("ipAddress", payload.ip);
      localStorage.setItem("dob", payload.dob);
      localStorage.setItem("generation", payload.generation);
      return axios
        .post(
          `${process.env.VUE_APP_API_URL}/manage-players/store`,
          payload
        )
        .then((res) => {
          if (res.data.status === 200 && res.data.success === true) {
            localStorage.setItem("user", true);
            return res.data.success;
          }
        })
        .catch((res) => {
          return false;
        });
    },
  },
};
