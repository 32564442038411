import axios from "axios";
import axiosInstance from "@/axiosInstance";

export default {
  namespaced: true,
  state: {
    previousAnswer:{}
  },

  mutations: {
    answerGivenByUser(state,data){
        state.previousAnswer = data;
    }
  },

  actions: {
    async previousQuestion(
        { commit },
        data
        ) {
      try {
        let game_played_id = JSON.parse(localStorage.getItem("scoreData")).game_played_id;
        let payload = {
            "ip_address": localStorage.getItem("ipAddress"),
            "generation": localStorage.getItem("generation"),
            "game_id":localStorage.getItem("game_id"),
            "game_played_id" : game_played_id,
            "question_id":parseInt(data)
        }
        let user = JSON.parse(localStorage.getItem("userDetails"));
        if (user && user.token != "") {
          return axiosInstance .post(`${process.env.VUE_APP_API_URL}/game/comparative-score`,payload)
          .then((res) => {
            if (res.data.success === true && res.data.code === 200) {
                commit("answerGivenByUser", res.data);
            }
          })
          .catch((res) => {
            console.log(res);
          });
        } else {
            await axios
            .post(`${process.env.VUE_APP_API_URL}/game/comparative-score`,payload)
            .then((res) => {
              if (res.data.success === true && res.data.code === 200) {
                  commit("answerGivenByUser", res.data);
              }
            })
            .catch((res) => {
              console.log(res);
            });

          }
       
      } catch (error) {
        console.error("Error fetching generation data:", error);
      }
    },

    
  },
};
