import axios from "axios";
import axiosInstance from "@/axiosInstance";
export default {
  namespaced: true,
  state: {
    gamePlayed:{
      
    },
    supportInfo: "",
    aboutInfo:"",
    informationInfo:""
  },

  mutations: {
    checkGamePlayed(state,data){
      state.gamePlayed = data;
  },
    getInfo(state, data) {
      state.supportInfo = data;
    },
    aboutUsInfo(state, data){
      state.aboutInfo = data;
    },
    getInstructionInfo(state,data){
      state.informationInfo = data;
    }
  },

  actions: {

    async isGamePLayed(
      /* eslint-disable */
      { commit }
      ) {
      try {
        let user = JSON.parse(localStorage.getItem("userDetails"));
        if (user && user.token !="") {
          return axiosInstance
            .post("/is-game-played")
            .then((res) => {
              if ( res.status === 200) {
                commit("checkGamePlayed", res.data);
              }
            })
            .catch((res) => {
              console.log(res);
            });
        } else {
          let payload={
            ip_address:localStorage.getItem('ipAddress')
          } 
          await axios
          .post(`${process.env.VUE_APP_API_URL}/is-game-played`,payload)
          .then((res) => {
            if ( res.status === 200) {
              commit("checkGamePlayed", res.data);
            }
          })
          .catch((res) => {
            console.log(res);
          });
        }
       
      } catch (error) {
        console.error("Error fetching generation data:", error);
      }
    },

    async supportInfoGetApi(
      /* eslint-disable */
      { commit }
    ) {
      try {
        await axios
          .get(`${process.env.VUE_APP_API_URL}/support`)
          .then((res) => {
            if (res.data.success === true && res.data.code === 200) {
              commit("getInfo", res.data.support_details);
            }
          })
          .catch((res) => {
            console.log(res);
          });
      } catch (error) {
        console.error("Error fetching generation data:", error);
      }
    },

    async userDropMessage(
      /* eslint-disable */
      { commit },
      payload
    ) {
      try {
      return  await axios
          .post(
            `${process.env.VUE_APP_API_URL}/contact-us`,
            payload
          )
          .then((res) => {
            if (res.data.success === true && res.data.code === 200) {
              return true;
            } else {
              return false;
            }
          })
          .catch((res) => {
            console.log(res);
          });
      } catch (error) {
        console.error("Error fetching generation data:", error);
      }
    },

    async aboutusGetApi(
      /* eslint-disable */
      { commit }
    ) {
      try {
        await axios
          .get(`${process.env.VUE_APP_API_URL}/about-us`)
          .then((res) => {
            if (res.data.success === true && res.data.status === 200) {
              commit("aboutUsInfo", res.data.data);
            }
          })
          .catch((res) => {
            console.log(res);
          });
      } catch (error) {
        console.error("Error fetching generation data:", error);
      }
    },

    async instructionGetApi(
      /* eslint-disable */
      { commit }
    ){
      try {
        await axios
          .get(`${process.env.VUE_APP_API_URL}/instruction`)
          .then((res) => {
          
            if (res.data.success === true && res.data.status === 200) {
              commit("getInstructionInfo", res.data.data);
            }
          })
          .catch((res) => {
            console.log(res);
          });
      } catch (error) {
        console.error("Error fetching generation data:", error);
      }
    }
  },
};
