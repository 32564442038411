import axiosInstance from "@/axiosInstance";

export default {
  namespaced: true,
  state: {},

  mutations: {},

  actions: {
    async userEditProfile(
      /* eslint-disable */
      { commit },
      payload
    ) {
      try {
        return axiosInstance
          .post("user/profile-update", payload)
          .then((res) => {
            console.log(res);
            let existingUserDetails = JSON.parse(
              localStorage.getItem("userDetails")
            );

            if (existingUserDetails) {
              let userData = {
                token: existingUserDetails.token,
                userDetails: res.data.data.user,
              };
              // Remove the old userDetails from local storage
              localStorage.removeItem("userDetails");

              // Store the updated userDetails back into local storage
              localStorage.setItem("userDetails", JSON.stringify(userData));
              return true;
              
            }
          })
          .catch((res) => {
            console.log(res);
          });
      } catch (error) {
        console.error("Error fetching generation data:", error);
      }
    },

    async changePassword(
      /* eslint-disable */
      { commit }
    ) {
      try {
        return axiosInstance
          .post("/user/change-password")
          .then((res) => {
            console.log(res);
          })
          .catch((res) => {
            console.log(res);
          });
      } catch (error) {
        console.error("Error fetching generation data:", error);
      }
    },
  },
};
