
import axios from "axios";
import axiosInstance from '@/axiosInstance';

export default {
  namespaced: true,
  state: {
   user:"",
   details:""
  },

  mutations: {
    loginUser(state,data){
      state.user = data;
    },
    userDetails(state){
      state.details  = JSON.parse(localStorage.getItem('userDetails'));
    }
  },

  actions: {

   
    async userSignUpFormSubmit(
       /* eslint-disable */
        { commit },
        payload
        ) {

      try {
       return  await axios
          .post(`${process.env.VUE_APP_API_URL}/registration`,payload)
          .then((res) => {
            return {
              status:true
            };
          })
          .catch((res) => {
           return  {
            status:false,
            response:res
            }
          });
      } catch (error) {
        alert("ok");
        console.error("Error fetching generation data:", error);
      }
    },

    async userSigninFormSubmit(
       { commit },
       payload
       ) {

     try {
       return axios
         .post(`${process.env.VUE_APP_API_URL}/login`,payload)
         .then((res) => {
           if(res.data.status.success === true){
            localStorage.clear();
            let userData = {
              token:res.data.status.token,
              userDetails:res.data['user-details'].user
            };
            localStorage.setItem('userDetails', JSON.stringify(userData));
            localStorage.setItem('dob', res.data['user-details'].user.dob);
            localStorage.setItem('generation', res.data['user-details'].user.generation);
            axios
            .get("https://api.ipify.org?format=json")
            .then((res) => {
              if (res.status === 200) {
                localStorage.setItem('ipAddress',res.data.ip);
              }
            })
            .catch((error) => {
              console.log(error);
            });
            commit("loginUser", res.data['user-details'].user);
            return {
              status :res.data.status.success
            }
           } 
         })
         .catch((res) => {
           console.log(res);
           return {
            status:false,
            response:res
           };
         });
     } catch (error) {
       console.error("Error fetching generation data:", error);
     }
    },

    async forgetPassword(
    /* eslint-disable */
     { commit },
     payload
     ) {
          try {
          return   await axios
              .post(`${process.env.VUE_APP_API_URL}/user/forgot-password`,payload)
              .then((res) => {
                return true;
              })
              .catch((res) => {
                console.log(res);
                return false;
              });
          } catch (error) {
            console.error("Error fetching generation data:", error);
          }     
    },

    async createPassword(
      /* eslint-disable */
       { commit },
       payload
       ) {
            try {
           return   await axios
                .post(`${process.env.VUE_APP_API_URL}/user/reset-password`,payload)
                .then((res) => {
                  return true;
                })
                .catch((res) => {
                  console.log(res);
                  return false;
                });
            } catch (error) {
              console.error("Error fetching generation data:", error);
            }     
      },


      async userLogOut(
        /* eslint-disable */
         { commit },
         ) {
              try {
                return  axiosInstance
                  .post('/logout')
                  .then((res) => {
                     if(res.data.code === 200 && res.data.success === true){
                      localStorage.clear();
                      return true;
                    }
                  })
                  .catch((res) => {
                    console.log(res);
                  });
              } catch (error) {
                console.error("Error fetching generation data:", error);
              }     
        },

  },
};
