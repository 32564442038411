
import Vue from 'vue';
import App from './App.vue';
import VueRouter from 'vue-router';
import routes from './router/route.js';
import store from './store/store.js';
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';



import shareIt from 'vue-share-it';

Vue.use(shareIt);


Vue.config.productionTip = false;

Vue.use(VueRouter);



Vue.use(Toast, {
  // You can specify options here if needed
});


new Vue({
  render: h => h(App),
  store,
  router: routes
}).$mount('#app');

