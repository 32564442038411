import axios from "axios";
import axiosInstance from "@/axiosInstance";
import Vue from "vue";

function getYoutubeVideoId(url) {
  if (!url) {
    return null;
  }

  // Split the URL by '/' and '?' to extract potential video ID
  var parts = url.split(/[/?]/);
  var potentialId = parts.find((part) => part.length === 11); // YouTube video IDs are typically 11 characters long

  return potentialId || null;
}

async function getYoutubeVideoStatics(id) {
  try {
    const response = await axios.get(
      `https://www.googleapis.com/youtube/v3/videos?id=${id}&key=AIzaSyA9MxxS1B_B5zwLW_XZZGQ3-YKefCZOlEI&part=snippet,statistics`
    );
    return response.data.items[0];
  } catch (error) {
    console.log(error);
  }
}

export default {
  namespaced: true,
  state: {
    historyData: [],
    questionState: {
      question: "",
    },
    gameID: "",
    iframe: {},
    finalScore: {
      ip_address: "",
      generation_id: "",
      dob: "",
      start_time: "",
      end_time: "",
      game_id: "",
      questions: [],
    },
    scoreData: {},
    rightAnswer: 0,
  },

  mutations: {
    HistoryData(state, data) {
      state.historyData = data;
    },
    resetState(state) {
      state.questionState = { question: "" };
      state.gameID = "";
      state.iframe = {};
      state.finalScore = {
        ip_address: "",
        generation_id: "",
        dob: "",
        start_time: "",
        end_time: "",
        game_id: "",
        questions: [],
      };
      state.scoreData = {};
      state.rightAnswer = 0;
    },

    scorePageLoading(state) {
      state.finalScore = JSON.parse(localStorage.getItem("finalScore"));
      state.scoreData = JSON.parse(localStorage.getItem("scoreData"));
      state.rightAnswer = localStorage.getItem("rightAnswer");
    },

    questionsData(state, data) {
      localStorage.setItem("game_id", data.game_id);
      Vue.set(state, "questionState", data);
      state.gameID = data.game_id;
      state.finalScore.game_id = data.game_id;
    },

    setPreviousUrl(state, data) {
      state.iframe = data;
    },

    calculateTime(state, data) {
      // Get the current date and time as a string in UTC format
      const now = new Date().toISOString();

      // Extract the date and time components
      const formattedDateTime = now.replace("T", " ").replace(/\.\d+Z$/, "");
      if (data === "start") {
        state.finalScore.start_time = formattedDateTime;
      }

      if (data === "end") {
        state.finalScore.end_time = formattedDateTime;
      }
    },
    FinalAnswerScore(state, data) {
      state.scoreData = data;
    },
  },

  actions: {
    async YoutubeApi(
      /* eslint-disable */
      { commit, state }
    ) {
      let data = {
        ...state,
      };

      let video1Id = getYoutubeVideoId(data.iframe.url_one);
      let video2Id = getYoutubeVideoId(data.iframe.url_two);

      const statistics1 = await getYoutubeVideoStatics(video1Id);
      const statistics2 = await getYoutubeVideoStatics(video2Id);

      if (statistics1 && statistics2) {
        const viewCount1 = parseInt(statistics1.statistics.viewCount);
        const viewCount2 = parseInt(statistics2.statistics.viewCount);
        let questionwiseAnswer = {
          images: {
            option_1: statistics1.snippet.thumbnails.high.url,
            option_2: statistics2.snippet.thumbnails.high.url,
          },
          views: {
            option_1: viewCount1,
            option_2: viewCount2,
          },
        };

        let answer = 0;

        if (data.iframe.selectOption == "A") {
          if (viewCount1 > viewCount2) {
            answer = 1;
            state.rightAnswer = state.rightAnswer + 1;
            questionwiseAnswer["id"] = data.iframe.questionId;
            questionwiseAnswer["option"] = "first_option";
            questionwiseAnswer["score"] = answer;
          } else {
            questionwiseAnswer["id"] = data.iframe.questionId;
            questionwiseAnswer["option"] = "first_option";
            questionwiseAnswer["score"] = answer;
          }
        } else if (data.iframe.selectOption == "B") {
          if (viewCount2 > viewCount1) {
            answer = 1;
            state.rightAnswer = state.rightAnswer + 1;
            questionwiseAnswer["id"] = data.iframe.questionId;
            questionwiseAnswer["option"] = "second_option";
            questionwiseAnswer["score"] = answer;
          } else {
            questionwiseAnswer["id"] = data.iframe.questionId;
            questionwiseAnswer["option"] = "second_option";
            questionwiseAnswer["score"] = answer;
          }
        }
        state.finalScore.questions.push(questionwiseAnswer);
        return true;
      }
    },

    async getHistoryDetails({ state, commit }, payload) {
      try {
        let user = JSON.parse(localStorage.getItem("userDetails"));
        if (user && user.token != "") {
          return axiosInstance
            .post("user/game-history-details", payload)
            .then((res) => {
              if (res.data.code === 200 && res.data.success == true) {
                state.finalScore.questions = res.data.questions;
                state.rightAnswer = 0;
                res.data.questions.map((res) => {
                  if (res.score == 1) {
                    state.rightAnswer = state.rightAnswer + 1;
                  }
                });
                localStorage.setItem("game_id", res.data.game_id);
                commit("FinalAnswerScore", res.data);
                return true;
              }
            })
            .catch((res) => {
              console.log("backend", res);
            });
        }
      } catch (error) {
        console.log(error);
      }
    },

    async SubmitAnswer({ commit, state }) {
      try {
        state.finalScore.ip_address = localStorage.getItem("ipAddress");
        state.finalScore.dob = localStorage.getItem("dob");
        state.finalScore.generation_id = localStorage.getItem("generation");
        let payload = "";
        payload = state.finalScore;
        let user = JSON.parse(localStorage.getItem("userDetails"));
        if (user && user.token != "") {
          return axiosInstance
            .post("game/answer", payload)
            .then((res) => {
              commit("FinalAnswerScore", res.data);
              return true;
            })
            .catch((res) => {
              console.log("backend", res);
            });
        } else {
          return await axios
            .post(`${process.env.VUE_APP_API_URL}/game/answer`, payload)
            .then((res) => {
              if (res.data.code === 200) {
                commit("FinalAnswerScore", res.data);
                return true;
              }
            })
            .catch((res) => {
              console.log("backend", res);
            });
        }
      } catch (error) {
        console.log("error");
        console.error("Error fetching generation data:", error);
      }
    },

    async quizQuestions(
      /* eslint-disable */
      { commit },
      payload
    ) {
      try {
        let url = "https://www.youtube.com/embed/";
        payload.ip_address = localStorage.getItem("ipAddress");

        let user = JSON.parse(localStorage.getItem("userDetails"));
        if (user && user.token != "") {
          return axiosInstance
            .post(`game/play`, payload)
            .then((res) => {
              if (res.status === 200 && res.data.success === true) {
                if (
                  res.data.question.url_one.startsWith(url) &&
                  res.data.question.url_two.startsWith(url)
                ) {
                  commit("questionsData", res.data);
                  return true;
                }
              }
            })
            .catch((res) => {
              console.log(res);
            });
        } else {
          return await axios
            .post(`${process.env.VUE_APP_API_URL}/game/play`, payload)
            .then((res) => {
              if (res.status === 200 && res.data.success === true) {
                if (
                  res.data.question.url_one.startsWith(url) &&
                  res.data.question.url_two.startsWith(url)
                ) {
                  commit("questionsData", res.data);
                  return true;
                }
              }
            })
            .catch((res) => {
              console.log(res);
            });
        }
      } catch (error) {
        console.error("Error fetching generation data:", error);
      }
    },
    async quizHistory(
      /* eslint-disable */
      { commit }
    ) {
      try {
        let user = JSON.parse(localStorage.getItem("userDetails"));
        if (user && user.token != "") {
          return axiosInstance
            .post(`user/game-history`)
            .then((res) => {
              if (res.data.status.success === true) {
                commit("HistoryData", res.data.status.game_details);
              }
            })
            .catch((res) => {
              console.log(res);
            });
        }
      } catch (error) {
        console.error("Error fetching generation data:", error);
      }
    },
  },
};
