// src/axiosInstance.js
import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: `${process.env.VUE_APP_API_URL}`, // Replace with your API base URL
  headers: {
    'Content-Type': 'application/json'
  }
});

// Add a request interceptor to include the token in the header
axiosInstance.interceptors.request.use(
  (config) => {
    const token = JSON.parse(localStorage.getItem('userDetails')).token; // Retrieve the token from localStorage or another storage
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
     // Conditionally set the Content-Type header for multipart/form-data
     if (config.url.includes('profile-update')) { // Replace '/your-specific-url' with the actual URL or endpoint
      config.headers['Content-Type'] = 'multipart/form-data';
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;
